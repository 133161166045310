* {
  box-sizing: border-box;
  position: relative;
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  outline: none;
  -webkit-appearance: none;
}

html, body {
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-weight: 200;
}

body {
  margin: 0;
  padding: 0;
}

.app-wrapper {
  display: block !important;
}

.app-loading {
  display: none;
}

.grid {
  display: block;
  text-align: center;
  font-size: 0;
}

.half {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  height: 100%;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.third {
  display: inline-block;
  vertical-align: top;
  width: 33.333%;
  height: 100%;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.side-padding-30 {
  padding: 0 30px;
}

.quarter {
  display: inline-block;
  vertical-align: top;
  width: 25%;
  height: 100%;

  @media (max-width: 768px) {
    width: 50%;
  }
}

.quarter-square {
  padding-top: 25%;
  background: #cccccc;
  background-size: cover;
  box-shadow: inset 0px 0px 60px rgba(0, 0, 0, 0.7);
  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: inset 0px 0px 60px rgba(0, 0, 0, 0);
  }

  @media (max-width: 768px) {
    padding-top: 50%;
  }
}

.vertical-center {
  display: inline-block;
  vertical-align: middle;
}

.page-width {
  width: 100%;
  height: 100%;
  margin: auto;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1230px) {
    max-width: 1200px;
  }

  @media (max-width: 600px) {
    padding: 0 30px;
  }
}

.main-header {
  padding: 150px 0;
  height: 830px;
  background: #cccccc;
  text-align: center;
  border-bottom: solid 4px #e3ddd8;
  background-color: #e3ddd8;
  background-position: center;
  background-size: auto 100%;

  @media (max-width: 768px) {
    padding: 75px 0;
    height: 100%;
  }

  @media (max-width: 768px) {
    background-position: 30%;
    height: auto;
  }
}

.mobile-header {
  display: none;
  background-position: 60%;
  background-size: auto 100%;

  @media (max-width: 768px) {
    display: block;
    background-position: 59.9%;
    height: 500px;
  }
}

.main-header a {
  font-size: 14px;
}

.logo {
  display: block;
  width: 200px;
  height: 120px;

  img {
    width: auto;
    height: 100%;
  }
}

.headlines {
  padding: 60px 0 50px;

  h1 {
    margin: 0;
    text-shadow: 0px 0px 4px #8a6c53, 0px 0px 4px #8a6c53, 0px 0px 4px #8a6c53, 0px 0px 4px #8a6c53, 0px 0px 4px #8a6c53, 0px 0px 4px #8a6c53, 0px 0px 4px #8a6c53, 0px 0px 4px #8a6c53;
    letter-spacing: 1px;
    font-family: 'Bangers', cursive;
    font-size: 65px;
    color: #fff300;

    @media (max-width: 768px) {
      font-size: 45px;
    }
  }

  h2 {
    width: 80%;
    font-size: 30px;
    font-weight: 400;
    color: #888899;

    @media (max-width: 768px) {
      font-size: 25px;
    }
  }
}

.cta-btn {
  padding: 0.5em 0.75em;
  border: none;
  border-radius: 0;
  background: #ef3c33;
  font-family: 'Bangers', cursive;
  font-size: 2.1em;
  font-weight: 900;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #f5eda4;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: #b32a21;
  }

  &:active {
    background: #444444;
  }
}

.features-section {
  padding: 30px 0 50px;

  h3 {
    font-size: 23px;
    font-weight: bold;
    color: #555555;
  }

  p {
    font-size: 18px;
    font-weight: 300;
    color: #777777;
  }
}

.main-description {
  width: 100%;
  max-width: 800px;
  line-height: 1.7;
}

.booking-title {
  padding: 20px 0;
  background: #ef3c33;

  .page-width {
    font-family: 'Bangers', cursive;
    font-size: 3em;
    font-weight: 900;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #f5eda4;
  }
}

.book-description {
  a {
    text-decoration: none;
    font-weight: bold;
    color: #ef3c33;
  }
}